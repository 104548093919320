import React from "react";
import { Text } from "basis";
import { CURRENCY, PAYMENT_OPTIONS } from "../../../../core/constants";
import {
  FEES,
  RATES,
} from "../../../../components/Promotions/Description/constants";
import { Minimum } from "./Minimum";
import { calculateTotals } from "../../../../components/Promotions/Description/utils";

const maxLengthShortTerm = 35;

export const Equal = ({ order, au, nz }) => {
  const productPrice = order?.price / 100;

  const AU = () => {
    if (isNaN(order?.price)) {
      console.info(`invalid price`);
      return <Minimum au={true} />;
    }

    if (isNaN(order?.promotionMonths)) {
      console.info(`invalid promo months`);
      return <Minimum au={true} />;
    }

    const { monthlyFees, monthlyPrice, totalPrice } = calculateTotals(
      productPrice,
      CURRENCY["AUD"],
      order?.promotionMonths
    );

    return (
      <>
        <Text textStyle="subtitle2" align="center" margin="6 0 0">
          <strong>{order?.productName || `The selected product`}</strong> is
          available on a Latitude Interest Free Payment Plan with a Latitude Gem
          Visa credit card ${monthlyPrice} per month for{" "}
          {order?.promotionMonths} months (excludes monthly account fee $
          {monthlyFees}). Total estimated price is ${totalPrice} including
          monthly account fee.
        </Text>
        <Text textStyle="subtitle2" align="center" margin="3 0 0">
          New customers must apply and be approved for a Latitude Gem Visa
          credit card.
        </Text>
      </>
    );
  };

  const NZ = () => {
    if (
      order?.promotionMonths >= maxLengthShortTerm &&
      order?.paymentOption === PAYMENT_OPTIONS.EQUAL
    ) {
      return <Minimum nz={true} />;
    }

    return (
      <>
        <Text textStyle="body2" margin="0 0 3" align="center">
          Approved customers only. Instalment Interest Free with Gem Visa or Gem
          CreditLine as an Instalment Plan Advance/CreditLine Select Advance.
          Amount payable will be shown on your monthly statement. Minimum and
          maximum purchase amounts may apply. As the Instalment Interest Free
          plan is for a period of less than 36 months, the minimum monthly
          payment under this plan is greater than the usual interest free
          repayment for the same term. Please refer to your Gem Visa or Gem
          CreditLine Conditions of Use in regard to the payment allocation for
          this plan.
        </Text>
        <Text textStyle="body2" margin="0 0 3" align="center">
          If you don't make your minimum monthly payment as provided in your
          statement, for two consecutive months, you agree that all Instalment
          Interest Free plans with a period of less than 36 months will change
          to an Interest Free plan for the remainder of the original plan's
          term.
        </Text>
        <Text textStyle="body2" margin="0 0 3" align="center">
          If there's an outstanding balance after the interest free period ends,
          interest will be charged at the prevailing interest rate (currently
          Gem Visa {RATES["GEM_VISA_NZ"]}% p.a. / Gem CreditLine{" "}
          {RATES["GEM_CREDITLINE"]}% p.a.). Credit and lending criteria and
          fees, including a Gem Visa ${FEES["ESTABLISHMENT"]} establishment fee
          and ${FEES["GEM_VISA"]} annual fees - (charged $
          {FEES["GEM_VISA_HALF_YEARLY"]} half yearly (Gem Visa/CreditLine))
          apply. Credit is provided by Latitude Financial Services Limited.
        </Text>
      </>
    );
  };

  return (
    <>
      {au && <AU />}
      {nz && <NZ />}
    </>
  );
};
