import React from "react";
import styled from "styled-components";
import { Text } from "basis";
import { Icon } from "../../../components";
import pageDataAu from "../page-data-au.json";
import pageDataNz from "../page-data-nz.json";
import { BREAKPOINT } from "../../../components/theme";

const ProofPointContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 880px;
  margin: 0 auto;
  @media (min-width: ${BREAKPOINT.SIZE_MOBILE}) {
    flex-wrap: nowrap;
    justify-content: space-around;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 30px 0;
  justify-self: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &:last-of-type {
    margin-bottom: 60px;
  }

  p[data-testid="heading"] {
    width: 100%;
  }

  @media (min-width: ${BREAKPOINT.SIZE_MOBILE}) {
    width: 33.33333%;
    margin: 0 30px;
    &:last-of-type {
      margin: 0 0 0 30px;
    }
    &:first-of-type {
      margin-left: 0;
    }
  }
`;

const ProofPoints = ({ au = false, nz = false, page }) => {
  const pageData = au ? pageDataAu : pageDataNz;

  return (
    <ProofPointContainer>
      {pageData.proofPoints.map((item) => (
        <Wrapper key={item.title}>
          <Icon type={item.icon} center size="150px" />
          <Text
            testId="heading"
            align="center"
            textStyle="heading5"
            margin="4 0 1">
            {item.title}
          </Text>
          <Text textStyle="body2" align="center">
            {item.description}
          </Text>
        </Wrapper>
      ))}
    </ProofPointContainer>
  );
};

export default ProofPoints;
