import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Stack, Text, Link } from "basis";
import ReactHTMLParser from "react-html-parser";

import { assetsBaseUrl } from "../../../core/config";
import { EVENTS, ACTION, COUNTRY } from "../../../core/constants";
import pageDataAu from "../page-data-au.json";
import pageDataNz from "../page-data-nz.json";
import { getEventDetail } from "../../../core/event-logger-helper";
import { logger } from "../../../core/logger";
import { generateURL, LOCATION, URL_TYPE } from "../../../services/url";
import { BREAKPOINT } from "../../../components/theme";

const CardArt = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  p {
    margin: 15px 0 30px;
  }
  a {
    width: 100%;
    margin-bottom: 35px;
  }
  @media (min-width: ${BREAKPOINT.SIZE_MOBILE}) {
    display: none;
  }
`;

const StyledCardArtImg = styled.img`
  height: ${({ isVertical }) => (isVertical ? "60px" : "40px")};
  margin-right: 5px;
  display: block;
`;

const MobileFooter = ({ au, nz, merchantId = "", page }) => {
  const country = au ? COUNTRY.AU : COUNTRY.NZ;

  const [applyURL, setApplyURL] = useState("");
  const [learnMoreURL, setLearnMoreURL] = useState("");

  useEffect(() => {
    setApplyURL(
      generateURL(merchantId, country, URL_TYPE.APPLY, LOCATION.ABOUT_PAGE)
    );

    setLearnMoreURL(
      generateURL(merchantId, country, URL_TYPE.LEARN_MORE, LOCATION.ABOUT_PAGE)
    );
  }, [merchantId]);

  const pageData = au ? pageDataAu : pageDataNz;

  const onApplyNowClick = (e) => {
    e.preventDefault();

    const otherDetails = {
      country,
    };

    const details = getEventDetail({
      eventName: EVENTS.Apply,
      action: ACTION.Click,
      page,
      otherDetails,
    });

    logger.info(details);
    window.location.replace(applyURL);
  };

  const onLearnMoreClick = (e) => {
    e.preventDefault();

    const otherDetails = {
      country,
    };

    const details = getEventDetail({
      eventName: EVENTS.LearnMore,
      action: ACTION.Click,
      page,
      otherDetails,
    });

    logger.info(details);
    window.open(learnMoreURL, "_blank");
  };

  const logos = au
    ? ["gem.png", "Go.png", "Creditline.png", "BuyersEdge.png"]
    : ["GemVisaNZ_Vertical.png", "CreditlineNZ.png"];

  return (
    <CardArt>
      <Link
        appearance="primary-button"
        variant="green-button"
        href={applyURL}
        newTab={true}
        onClick={(e) => onApplyNowClick(e)}
      >
        Apply now
      </Link>
      <Link
        href={learnMoreURL}
        newTab={true}
        onClick={(e) => {
          onLearnMoreClick(e);
        }}
      >
        Learn more
      </Link>
      <Stack gap="2" direction="horizontal">
        {logos.map((logo) => (
          <StyledCardArtImg
            key={logo}
            src={`${assetsBaseUrl}/latitude/acceptedCard/${logo}`}
            isVertical={logo.includes("Vertical")}
          />
        ))}
      </Stack>
      <Text>{ReactHTMLParser(pageData.cardsText)}</Text>
    </CardArt>
  );
};

export default MobileFooter;
