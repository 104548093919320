import React from "react";
import { PAYMENT_OPTIONS } from "../../../../core/constants";
import { Equal } from "./Equal";
import { Minimum } from "./Minimum";
import { Deferred } from "./Deferred";
import { Fallback } from "./Fallback";
import { LessThanMinAmount } from "./LessThanMinAmount";

const Terms = (props) => {
  const type = props?.order?.paymentOption || {};
  const priceBreakdown = props?.order?.ppm;
  const au = props?.au || "";
  const nz = props?.nz || "";

  if (priceBreakdown === false && type === PAYMENT_OPTIONS.EQUAL) {
    return <LessThanMinAmount au={au} />;
  }

  if (type === PAYMENT_OPTIONS.EQUAL) {
    return <Equal {...props} />;
  }

  if (type === PAYMENT_OPTIONS.MINIMUM) {
    return <Minimum {...props} />;
  }

  if (type === PAYMENT_OPTIONS.DEFERRED && nz) {
    return <Deferred {...props} />;
  }

  return <Fallback {...props} />;
};

export default Terms;
