import React from "react";
import { Text } from "basis";
import {
  MONTHLY_FEES,
  FEES,
  RATES,
} from "../../../../components/Promotions/Description/constants";
import { currencyFormat } from "../../../../utils";
import { LessThanMinAmount } from "./LessThanMinAmount";
import {
  LOCATION,
  WIDGET_DEFAULT_MINIMUM_AMOUNT_CENTS,
} from "../../../../../assets/constants";

export const Minimum = ({ au, nz, order }) => {
  if (
    order?.location === LOCATION.WIDGET &&
    order?.price < WIDGET_DEFAULT_MINIMUM_AMOUNT_CENTS
  ) {
    return <LessThanMinAmount au={au} nz={nz} />;
  }

  const AU = () => (
    <>
      <Text align="center" margin="3 0">
        Monthly payments must be made during the promotional period. Paying only
        the minimum monthly payment will not pay out the purchase before the end
        of the plan. At the end of the plan, interest will be charged at{" "}
        {RATES["GEM_VISA_AU"]}% p.a. Account service fee of $
        {MONTHLY_FEES["AUD"]} per month applies.
      </Text>
      <Text align="center" margin="3 0">
        New customers must apply &amp; be approved for a Latitude Gem Visa
        credit card.
      </Text>
    </>
  );

  const NZ = () => (
    <>
      <Text textStyle="body2" margin="0 0 3" align="center">
        T&amp;Cs &amp; fees apply incl. a ${FEES["ESTABLISHMENT"]} Gem Visa
        establishment fee &amp; ${FEES["GEM_VISA"]} annual fees - (charged $
        {FEES["GEM_VISA_HALF_YEARLY"]} half yearly (Gem Visa/CreditLine)).
        Interest rate (currently Gem Visa {RATES["GEM_VISA_NZ"]}% p.a. / Gem
        CreditLine {RATES["GEM_CREDITLINE"]}% p.a.) applies after interest free
        term ends. Credit provided by Latitude Financial Services Ltd.
      </Text>
    </>
  );

  return (
    <>
      {au && <AU />}
      {nz && <NZ />}
    </>
  );
};
