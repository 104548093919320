import React from "react";
import { Text } from "basis";

import {
  FEES,
  RATES,
} from "../../../../components/Promotions/Description/constants";
import { WIDGET_DEFAULT_MINIMUM_AMOUNT_CENTS } from "../../../../../assets/constants";
import { LessThanMinAmount } from "./LessThanMinAmount";

export const Deferred = ({ order }) => {
  if (order?.price < WIDGET_DEFAULT_MINIMUM_AMOUNT_CENTS) {
    return <LessThanMinAmount />;
  }

  return (
    <>
      <Text textStyle="body2" margin="0 0 3" align="center">
        T&amp;Cs &amp; fees apply incl. a ${FEES["ESTABLISHMENT"]} Gem Visa
        establishment fee &amp; ${FEES["GEM_VISA"]} annual fees - (charged $
        {FEES["GEM_VISA_HALF_YEARLY"]} half yearly (Gem Visa/CreditLine)).
        Interest rate (currently Gem Visa {RATES["GEM_VISA_NZ"]}% p.a. / Gem
        CreditLine {RATES["GEM_CREDITLINE"]}% p.a.) applies after interest free
        term ends. Credit provided by Latitude Financial Services Ltd.
      </Text>
    </>
  );
};
