import React from "react";
import styled from "styled-components";
import { BREAKPOINT } from "../../../components/theme";

const StyledAboutScene = styled.div`
  @media (min-width: ${BREAKPOINT.SIZE_MOBILE}) {
    border-radius: 10px;
    max-width: 100%;
    margin: 0 auto 50px;
  }
`;

const AboutScene = ({ children }) => {
  return <StyledAboutScene>{children}</StyledAboutScene>;
};

export default AboutScene;
