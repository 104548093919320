import React from "react";
import { Text } from "basis";

export const LessThanMinAmount = ({ au }) => {
  return (
    <>
      <Text textStyle="subtitle2" align="center" margin="6 0 0">
        Interest free promotions are available depending on your total cart
        amount. Please don't forget to check the Interest Free payment option at
        checkout to see if you are eligible based on your purchase.
      </Text>

      {au && (
        <Text textStyle="subtitle2" align="center" margin="3 0 0">
          New customers must apply and be approved for a Latitude Gem Visa
          credit card.
        </Text>
      )}
    </>
  );
};
