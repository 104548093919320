import React from "react";
import styled from "styled-components";
import { assetsBaseUrl } from "../../../core/config";
import pageDataAu from "../page-data-au.json";
import pageDataNz from "../page-data-nz.json";
import { Text, Flex } from "basis";
import ReactHTMLParser from "react-html-parser";
import { BREAKPOINT } from "../../../components/theme";

const StyledCardArt = styled.div`
  display: none;
  flex-wrap: wrap;
  justify-content: start;
  p {
    margin: 15px 0 30px;
  }
  @media (min-width: ${BREAKPOINT.SIZE_MOBILE}) {
    display: flex;
    justify-content: center;
    > div {
      width: 100%;
    }
  }
`;

const StyledCardArtImg = styled.img`
  height: ${({ isVertical }) => (isVertical ? "60px" : "40px")};
  margin-right: 5px;
  display: block;
`;

const CardArt = ({ au = false, nz = false, page }) => {
  const logos = au
    ? ["Gem.png", "GO.png", "Creditline.png", "BuyersEdge.png"]
    : ["GemVisaNZ_Vertical.png", "CreditlineNZ.png"];

  const pageData = au ? pageDataAu : pageDataNz;

  return (
    <StyledCardArt>
      <Flex placeItems="center" margin="8 0 0 0">
        {logos.map((logo) => (
          <StyledCardArtImg
            key={logo}
            src={`${assetsBaseUrl}/latitude/acceptedCard/checkout/${logo}`}
            isVertical={logo.includes("Vertical")}
          />
        ))}
      </Flex>
      <Text align="center">{ReactHTMLParser(pageData.cardsText)}</Text>
    </StyledCardArt>
  );
};

export default CardArt;
