import React, { useEffect } from "react";
import styled from "styled-components";
import { Stack, Grid } from "basis";
import { EVENTS, ACTION, COUNTRY } from "../../core/constants";
import pageDataAu from "./page-data-au.json";
import pageDataNz from "./page-data-nz.json";
import { Icon } from "../../components";
import { getEventDetail } from "../../core/event-logger-helper";
import { logger } from "../../core/logger";
import {
  ProofPoints,
  MobileFooter,
  HeadingContainer,
  AboutScene,
} from "./components";
import { BREAKPOINT, COLOR } from "../../components/theme";

const AboutHeading = styled.div`
  background: ${COLOR.SECONDARY_LIGHT_BLUE_25};
  padding: 35px 20px;

  svg {
    margin: 0 auto 35px;
    width: 300px;
    display: block;
  }

  p[data-testid="formatted-price"] {
    display: inline;
    font-size: 18px;
  }

  a {
    margin: 30px auto 0;
    display: block;
    text-align: center;
  }

  div[data-testId="content-holder"] {
    max-width: 880px;
    margin: 0 auto;
  }

  @media (min-width: ${BREAKPOINT.SIZE_MOBILE}) {
    padding: 40px;
    a {
      max-width: 250px;
    }

    .no-margin {
      a {
        margin-top: 0;
      }
    }
  }
  a[data-testid="terms"] {
    max-width: none;
  }
`;
const AboutProofPoints = styled.div`
  padding: 35px 20px;
  @media (min-width: ${BREAKPOINT.SIZE_MOBILE}) {
    padding: 40px;
  }
`;

const Scene = ({ au = false, nz = false, merchantId = "", page, order }) => {
  useEffect(() => {
    const otherDetails = {
      country: au ? COUNTRY.AU : nz ? COUNTRY.NZ : "",
    };

    const details = getEventDetail({
      eventName: EVENTS.About,
      action: ACTION.Pageload,
      page,
      otherDetails,
    });

    logger.info(details);
  }, []);

  const pageData = au ? pageDataAu : pageDataNz;

  return (
    <AboutScene>
      <AboutHeading>
        <Grid preset="page" testId="content-holder">
          <Grid.Item colSpan="all">
            <Stack>
              <Icon className="logo" type={pageData.logoType} />
              <HeadingContainer
                au={au}
                nz={nz}
                merchantId={merchantId}
                page={page}
                order={order}
              />
            </Stack>
          </Grid.Item>
        </Grid>
      </AboutHeading>
      <AboutProofPoints>
        <ProofPoints au={au} nz={nz} />
        <MobileFooter au={au} nz={nz} merchantId={merchantId} page={page} />
      </AboutProofPoints>
    </AboutScene>
  );
};

export default Scene;
